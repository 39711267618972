import { cateringRequest, cateringRequestHide, cateringUploadsHide } from '@/utils/fetch'

interface saveReq {
  id?: number
  project_id?: number
  staff_id?: number
  file_name: string
  file: Array<string>
}
export type saveProject = saveReq //导出请求保存的数据结构

// 资料汇总 添加接口
export function materialSave(data: saveReq) {
  const url = '/api/project_material/save'
  return cateringRequest.post(url, data)
}
